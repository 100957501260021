import React, { useRef, useState } from "react";
import Images from "../Images";
import tw, { styled } from "twin.macro";
import Config from "../Config";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/bundle";
import { useMutation } from "react-query";
import Loading from "../components/Loading";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HiArrowNarrowRight } from "react-icons/hi";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { IoIosArrowBack } from "react-icons/io";
import { BsCheck2 } from "react-icons/bs";
// import { MdCheck } from "react-icons/md";
import { FaCheck, FaUserAlt } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
// import { BiSolidUserCircle } from "react-icons/bi";
import { MdEmail, MdPassword, MdSmartphone } from "react-icons/md";

const SignUpPage = () => {
  const data = [
    {
      id: 1,
      name: "Your Centralized Messaging Solution",
      img: Images.RedBg,
      zoner:
        "Manage all your SMS communications from a single hub, ensuring streamlined and organized conversations. ",
    },
    {
      id: 2,
      name: "Reach Your Audience with SMS",
      img: Images.BlackBg,
      zoner:
        "InstantReach is a dynamic SMS sending platform that enables you to instantly reach your audience. ",
    },
    {
      id: 3,
      name: "Instant Communication Made Easy",
      img: Images.BlueBg,
      zoner:
        "Stay connected and engage with your audience in an instant, whether it's for important announcements, promotions, or general communication.",
    },
    {
      id: 4,
      name: "Bridge the Communication Gap with SMS",
      img: Images.PurpleBg,
      zoner:
        "Seamlessly connect with your audience, customers, or community through personalized text messages.",
    },
  ];
  const navigate = useNavigate();
  const captchaRef = useRef(null);
  const [message, setMessage] = useState("Create your account");
  const [stepState, setStepState] = useState({
    1: {
      active: true,
      completed: false,
    },
    2: {
      active: false,
      completed: false,
    },
    3: {
      active: false,
      completed: false,
    },
  });
  const [forgotPassword, setForgotPassword] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  // const [loginLoading, setLoginLoading] = useState(false);
  const [formVal, setFormVal] = useState({
    emailMobile: "",
    password: "",
  });

  const loginFunction = async (body) => {
    return await axios.post(`${Config.baseUrl}/customer/signUp`, body, {
      headers: {
        "Content-Type": "application/json ",
      },
    });
  };

  const onloginSuccess = (data) => {
    // localStorage.setItem("user", JSON.stringify(data?.data));
    // window.location = Config.cmsUrl + "/cms";
    // toast.success(data?.data?.msg || "Success", {
    //   position: toast.POSITION.TOP_LEFT,
    // });
    // setMessage(data?.data?.msg || "Success");
    setMessage("A 4-digit OTP has been sent to your mobile number");

    setStepState((prev) => ({
      ...prev,
      1: { active: true, completed: true },
      2: { active: true, completed: false },
      3: { active: false, completed: false },
    }));
    // navigate("/otp");
  };

  const onloginError = (err) => {
    toast.error(err.response.data.msg || "An Error Occured", {
      position: toast.POSITION.TOP_LEFT,
    });
  };

  const { isLoading: loginLoading, mutate: loginMutate } = useMutation(
    loginFunction,
    {
      onSuccess: onloginSuccess,
      onError: onloginError,
    }
  );
  // otp
  const otpFunction = async (body) => {
    return await axios.post(`${Config.baseUrl}/customer/otpVerify`, body, {
      headers: {
        "Content-Type": "application/json ",
      },
    });
  };

  const onotpSuccess = (data) => {
    // localStorage.setItem("user", JSON.stringify(data?.data));
    // window.location = Config.cmsUrl + "/cms/welcome";
    // setStepState((prev) => ({
    //   ...prev,
    //   2: { active: true, completed: true },
    //   3: { active: true, completed: false },
    // }));

    setStepState((prev) => ({
      ...prev,
      2: { active: true, completed: true },
      3: { active: true, completed: false },
    }));
    setMessage("You're almost done");
  };

  const onotpError = (err) => {
    toast.error(err.response.data.msg || "An Error Occured", {
      position: toast.POSITION.TOP_LEFT,
    });
  };

  const { isLoading: otpLoading, mutate: otpMutate } = useMutation(
    otpFunction,
    {
      onSuccess: onotpSuccess,
      onError: onotpError,
    }
  );
  // new password api

  const passwordFunction = async (body) => {
    return await axios.post(
      `${Config.baseUrl}/customer/createPassword
    `,
      body,
      {
        headers: {
          "Content-Type": "application/json ",
        },
      }
    );
  };

  const onPasswordSuccess = (data) => {
    localStorage.setItem("user", JSON.stringify(data?.data));
    window.location = Config.cmsUrl + "/cms/welcome";
  };

  const onPasswordError = (err) => {
    toast.error(err.response.data.msg || "An Error Occured", {
      position: toast.POSITION.TOP_LEFT,
    });
  };

  const { isLoading: passwordLoading, mutate: passwordMutate } = useMutation(
    passwordFunction,
    {
      onSuccess: onPasswordSuccess,
      onError: onPasswordError,
    }
  );
  //

  const [otpVal, setOtpVal] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
  });

  const OtpChangeHandler = (e) => {
    const { value, name } = e.target;
    if (value) {
      const length = value.length;
      if (value.length === 1) {
        setOtpVal((prev) => ({ ...prev, [name]: value }));
        if (name === "first") document.getElementsByName("second")[0]?.focus();
        else if (name === "second")
          document.getElementsByName("third")[0]?.focus();
        else if (name === "third")
          document.getElementsByName("fourth")[0]?.focus();
      }
    } else {
      setOtpVal((prev) => ({ ...prev, [name]: value }));
      if (name === "fourth") document.getElementsByName("third")[0]?.focus();
      else if (name === "third")
        document.getElementsByName("second")[0]?.focus();
      else if (name === "second")
        document.getElementsByName("first")[0]?.focus();
    }
  };

  //
  const initialValues = {
    loginId: "",
    password: "",
    // mode: mode || "1",
  };

  const handlePrevious = () => {
    if (stepState[3].active) {
      setStepState((prev) => ({
        ...prev,
        2: { active: true, completed: false },
        3: { active: false, completed: false },
      }));
      setMessage("A 4-digit OTP has been sent to your mobile number");

      // Reset the OTP input fields
      setOtpVal({
        first: "",
        second: "",
        third: "",
        fourth: "",
      });
      // setPasswordValue("");
    } else if (stepState[2].active) {
      setStepState((prev) => ({
        ...prev,
        1: { active: true, completed: false },
        2: { active: false, completed: false },
      }));
      setMessage("Create your account");
      setOtpVal({
        first: "",
        second: "",
        third: "",
        fourth: "",
      });
    }
  };

  const submitHandler = (values) => {
    const body = new FormData();

    if (stepState[1].active && !stepState[1].completed) {
      if (values.mobile.toString().length > 9) {
        return toast.error("Mobile number should not exceeds 9 digits", {
          position: toast.POSITION.TOP_LEFT,
        });
      } else if (values.mobile.toString().length < 9) {
        return toast.error("Please enter a valid mobile number", {
          position: toast.POSITION.TOP_LEFT,
        });
      }

      body.append("name", values.username);
      body.append("email", values.email);
      body.append("mobilenumber", values.mobile);
      body.append("mode", "1");

      // Trigger the signup API here
      loginMutate(body);
    } else if (stepState[2].active && !stepState[2].completed) {
      body.append(
        "otp",
        otpVal?.first + otpVal?.second + otpVal?.third + otpVal?.fourth
      );
      body.append("email", values.email);
      body.append("mobilenumber", values.mobile);

      // Trigger the OTP verification API here
      otpMutate(body);
    } else {
      if (values.password !== values.confirmpassword) {
        return toast.error("Password & confirm password must be matched ", {
          position: toast.POSITION.TOP_LEFT,
        });
      }
      if (values.password.length < 8) {
        return toast.error("Password should be atleast 8 characters", {
          position: toast.POSITION.TOP_LEFT,
        });
      }

      body.append("email", values.email);
      body.append("password", values.password);
      body.append("confirmpassword", values.confirmpassword);

      // Trigger the create password API here
      passwordMutate(body);
    }
  };

  function onChange(value) {
    // setCapcha(!isCapchaChecked);
  }
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const marginLeft = isMobile ? -10 : -21;
  return (
    <Login>
      <TextWrapperComp
        initialValues={initialValues}
        submitHandler={submitHandler}
        // alert={alert}
        captchaRef={captchaRef}
        onChange={onChange}
        loginLoading={loginLoading}
        otpLoading={otpLoading}
        OtpChangeHandler={OtpChangeHandler}
        otpVal={otpVal}
        showOtp={showOtp}
        stepState={stepState}
        message={message}
        handlePrevious={handlePrevious}
        passwordLoading={passwordLoading}
        marginLeft={marginLeft}
        isMobile={isMobile}
      />
      <ImageWrapper>
        <CarouselContainer data={data} />
      </ImageWrapper>
    </Login>
  );
};

const StepComp = ({ stepState, stepNum }) => {
  return (
    <>
      {stepState[stepNum].active ? (
        <ColoredStep>
          {stepState[stepNum].completed ? (
            <FaCheck className="w-6 h-6 text-white" />
          ) : (
            stepNum
          )}
        </ColoredStep>
      ) : (
        <Step>{stepNum}</Step>
      )}
      {stepNum != 3 &&
        (stepState[stepNum].completed ? <ColoredStepLine /> : <StepLine />)}
    </>
  );
};

const TextWrapperComp = ({
  initialValues,
  submitHandler,
  onChange,
  loginLoading,
  captchaRef,
  OtpChangeHandler,
  otpVal,
  showOtp,
  stepState,
  otpLoading,
  message,
  handlePrevious,
  passwordLoading,
  marginLeft,
  isMobile,
}) => (
  <TextWrapper>
    <Container className="Container">
      <LogoHeading>
        <Link to="/">
          <Logo src={Images.Logo} alt="rapid test and trace logo" />
        </Link>
        {/* <Heading> Developer Portal </Heading> */}
      </LogoHeading>

      <Center>
        <Steps>
          <StepComp stepState={stepState} stepNum={1} />
          <StepComp stepState={stepState} stepNum={2} />
          <StepComp stepState={stepState} stepNum={3} />
        </Steps>

        <Formik initialValues={initialValues} onSubmit={submitHandler}>
          <Form>
            <FormContainer>
              <p
                className="flex flex-row items-center space-x-2"
                style={{ marginLeft: stepState[1].completed ? marginLeft : 0 }}
                // style={{ marginLeft: stepState[1].completed ? -21 : 0 }}
              >
                {stepState[1].completed && (
                  <BackArrow onClick={handlePrevious}>
                    <IoIosArrowBack style={{ width: 20, height: 20 }} />
                  </BackArrow>
                )}

                <Title>
                  {" "}
                  {stepState[1].completed && !stepState[2].completed
                    ? "Enter OTP"
                    : stepState[2].completed
                    ? "Create Password"
                    : "Sign Up"}
                </Title>
              </p>

              {/* <Desc style={{ marginLeft: !stepState[1].completed ? 0 : 3 }}>
                {message}
              </Desc> */}
              <Desc
                style={{
                  marginLeft: stepState[1].completed ? (isMobile ? 12 : 0) : 0,
                }}
              >
                {message}
              </Desc>

              {stepState[1].active && !stepState[1].completed && (
                <>
                  <InputGroup>
                    <FieldWrapper>
                      <NameIcon className="icon" />
                      <Field
                        name="username"
                        type="text"
                        id="userName"
                        required
                        autoComplete="off"
                        placeholder="Name"
                        className="loginInput"
                        style={{
                          marginLeft: 32,
                          outline: "none",
                          borderRadius: "0px",
                        }}
                      />
                    </FieldWrapper>
                  </InputGroup>

                  <InputGroup>
                    <FieldWrapper>
                      <MailIcon className="icon" />
                      <Field
                        name="email"
                        type="email"
                        id="email"
                        required
                        autoComplete="off"
                        placeholder="Email"
                        className="loginInput"
                        style={{
                          marginLeft: 32,
                          outline: "none",
                          borderRadius: "0px",
                        }}
                      />
                    </FieldWrapper>
                  </InputGroup>

                  <InputGroup>
                    <FieldWrapper>
                      <PhoneIcon className="icon" />

                      <Field
                        name="mobile"
                        type="number"
                        id="mobile"
                        required
                        autoComplete="off"
                        placeholder="Mobile Number"
                        className="loginInput"
                        style={{
                          marginLeft: 32,
                          outline: "none",
                          borderRadius: "0px",
                        }}
                      />
                    </FieldWrapper>
                  </InputGroup>

                  <SubmitButton type="submit" disabled={loginLoading}>
                    {loginLoading && (
                      <Loading
                        color="white"
                        width={20}
                        height={20}
                        noPadding={true}
                      />
                    )}
                    {
                      !loginLoading && "SignUp"
                      // (stepState[3].active ? "SignUp" : "Next")
                    }
                  </SubmitButton>
                </>
              )}

              {stepState[2].active && !stepState[2].completed && (
                <>
                  {/* <Message>{message}</Message> */}
                  <OtpInputWrapper>
                    <OtpInput
                      type="number"
                      maxLength={1}
                      name="first"
                      value={otpVal.first}
                      required
                      onChange={OtpChangeHandler}
                      //   ref={firstOTP}
                    />
                    <OtpInput
                      type="number"
                      maxLength={1}
                      name="second"
                      value={otpVal.second}
                      required
                      onChange={OtpChangeHandler}
                      //   ref={secondOTP}
                    />
                    <OtpInput
                      type="number"
                      maxLength={1}
                      name="third"
                      value={otpVal.third}
                      required
                      onChange={OtpChangeHandler}
                      //   ref={thirdOTP}
                    />
                    <OtpInput
                      type="number"
                      maxLength={1}
                      name="fourth"
                      value={otpVal.fourth}
                      required
                      onChange={OtpChangeHandler}
                      //   ref={fourthOTP}
                    />
                  </OtpInputWrapper>

                  <SubmitButton type="submit" disabled={otpLoading}>
                    {otpLoading && (
                      <Loading
                        color="white"
                        width={20}
                        height={20}
                        noPadding={true}
                      />
                    )}
                    {
                      !otpLoading && "Verify OTP"
                      // (stepState[3].active ? "SignUp" : "Next")
                    }
                  </SubmitButton>
                </>
              )}

              {stepState[3].active && !stepState[3].completed && (
                <>
                  {/* <Message>Create your password</Message> */}
                  <InputGroup>
                    <FieldWrapper>
                      <PassIcon className="icon" />
                      <Field
                        name="password"
                        type="password"
                        id="password"
                        required
                        autoComplete="off"
                        placeholder="Password"
                        className="loginInput"
                        style={{
                          marginLeft: 32,
                          outline: "none",
                          borderRadius: "0px",
                        }}
                      />
                    </FieldWrapper>
                  </InputGroup>

                  <InputGroup>
                    <FieldWrapper>
                      <PassIcon className="icon" />
                      <Field
                        name="confirmpassword"
                        type="password"
                        id="confirmpassword"
                        required
                        autoComplete="off"
                        placeholder="Confirm Password"
                        className="loginInput"
                        style={{
                          marginLeft: 32,
                          outline: "none",
                          borderRadius: "0px",
                        }}
                      />
                    </FieldWrapper>
                  </InputGroup>
                  <SubmitButton type="submit" disabled={passwordLoading}>
                    {passwordLoading && (
                      <Loading
                        color="white"
                        width={20}
                        height={20}
                        noPadding={true}
                      />
                    )}
                    {
                      !passwordLoading && "Set Password"
                      // (stepState[3].active ? "SignUp" : "Next")
                    }
                  </SubmitButton>
                </>
              )}
            </FormContainer>
          </Form>
        </Formik>
      </Center>

      <BottomText>
        <p>Already have an account?</p>
        <Link to="/login">
          <LoginLink>Login</LoginLink>
        </Link>
      </BottomText>
    </Container>
  </TextWrapper>
);

const CarouselContainer = ({ data }) => {
  return (
    <SwiperGroup className="home-carousel">
      <Swiper
        className="h-screen"
        modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y]}
        keyboard={{
          enabled: true,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        spaceBetween={20}
        slidesPerView={1}
        loop={{ clickable: true }}
        // scrollbar={{ draggable: true }}
      >
        {data.map((value) => {
          const { id, name, img, zoner } = value;
          return (
            <SwiperSlide
              key={id}
              tw="relative h-screen border-2 border-blue-500"
            >
              <Image src={img} alt={name} />
              <TextOverlay>
                <OverlayTitle>{name}</OverlayTitle>
                <OverlayText> {zoner}</OverlayText>
              </TextOverlay>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </SwiperGroup>
  );
};
const Message = tw.p`text-gray-700 text-base mt-2`;
const Login = tw.section`h-screen overflow-hidden md:flex`;
const Logo = tw.img`h-8 w-auto mx-auto md:mx-0 mb-10 mt-8`;
const LogoHeading = tw.div`flex justify-start mt-5`;
const Heading = tw.h1`text-xl ml-6 text-black`;
const Container = tw.div`flex flex-col`;
const TextWrapper = tw.div`h-full w-full md:w-6/12 lg:w-4/12 relative md:overflow-x-hidden overflow-y-auto md:pl-8`;
const ImageWrapper = tw.div`hidden md:block md:w-6/12 lg:w-8/12 h-full`;
const Center = tw.div`grow flex flex-col`;
const Title = tw.h1`text-2xl text-black font-bold `;
const Desc = tw.h1`text-sm text-gray-600 font-normal !mb-10 !mt-1   `;
// const Desc = tw.h1`text-sm text-gray-600 font-normal !m-0 !mb-10 !mt-1  `;
const SubmitButton = tw.button`w-full   text-sm md:text-base  h-12 text-white grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed  !bg-primary-500  rounded-full shadow-md hover:shadow-lg mt-10`;
const BottomText = tw.p`text-sm mb-5 text-gray-800 flex justify-start`;
const ForgotPassword = tw.p`text-primary-500 text-sm ml-1  flex items-center justify-end`;
const SwiperGroup = tw.div` h-full overflow-hidden shadow-xl mx-auto md:w-full md:h-full opacity-100`;
const InputGroup = tw.div`w-full flex flex-col space-y-2 mt-5`;
const FieldWrapper = tw.div`h-10 overflow-hidden border border-gray-700 relative rounded-full w-full flex items-center`;
const Image = tw.img`cursor-pointer object-cover w-full h-full`;
const TextOverlay = tw.div`hidden md:block md:absolute md:top-1/2 md:left-0 md:w-full md:h-full px-10 transform -translate-y-32 `;
const OverlayTitle = tw.h2` md:text-4xl md:w-10/12 lg:text-5xl lg:w-7/12 text-white md:leading-tight font-bold`;
const OverlayText = tw.p`text-base mt-2 text-white md:w-10/12 lg:w-6/12 mt-5 leading-relaxed`;
const FormContainer = tw.div`mt-8 mr-6`;
const OtpInput = tw.input` w-12  text-center py-4 rounded-lg font-medium bg-gray-100 border border-gray-300 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white `;
const OtpInputWrapper = tw.div`w-full flex justify-between items-center mt-6`;

const Steps = tw.div`w-full flex items-center space-x-1 justify-center mt-5`;
const ColoredStep = tw.div`w-10 h-10 rounded-full flex justify-center items-center  bg-primary-500 text-white`;
const Step = tw.div`w-10 h-10 rounded-full flex justify-center items-center  bg-gray-300 text-gray-700`;
const StepLine = tw.div`w-16 h-1 rounded-full   bg-gray-300`;
const ColoredStepLine = tw.div`w-16 h-1 rounded-full   bg-primary-500`;
const LoginLink = tw.p`text-primary-500 font-medium ml-1`;
const PreviousButton = tw.button` mr-2 w-24 text-sm md:text-base  h-10 text-white grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed  !bg-gray-700  rounded-full shadow-md hover:shadow-lg mt-10`;

const BackArrow = tw.div`cursor-pointer  flex items-center justify-center`;
const NameIcon = styled(FaUserAlt)`
  ${tw`text-gray-600 absolute ml-4 top-1/2 transform -translate-y-1/2`}
`;
const MailIcon = styled(MdEmail)`
  ${tw`text-gray-600 absolute ml-4 top-1/2 transform -translate-y-1/2`}
`;
const PhoneIcon = styled(MdSmartphone)`
  ${tw`text-gray-600 absolute ml-4 top-1/2 transform -translate-y-1/2`}
`;
const PassIcon = styled(MdPassword)`
  ${tw`text-gray-600 absolute ml-4 top-1/2 transform -translate-y-1/2`}
`;
export default SignUpPage;
