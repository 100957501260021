import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Rnd } from "react-rnd";
import { motion } from "framer-motion";
import AnimationRevealPage from "./helpers/AnimationRevealPage.js";
import {
  Container,
  Content2Xl,
  ContentWithVerticalPadding,
} from "./components/misc/Layouts";
import tw from "twin.macro";
// import tw, { css } from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { SectionHeading as HeadingBase } from "./components/misc/Headings";
import { SectionDescription as DescriptionBase } from "./components/misc/Typography";
import { PrimaryButton as PrimaryButtonBase } from "./components/misc/Buttons.js";

import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as RadioIcon } from "feather-icons/dist/icons/radio.svg";
import Hero from "./components/hero/TwoColumnWithInput.js";
import Features from "./components/features/DashedBorderSixFeatures.js";
import Stats from "./components/ThreeColCenteredStatsPrimaryBackground.js";
import FeatureWithSteps from "./components/features/TwoColWithSteps.js";
import Pricing from "./components/pricing/ThreePlansWithHalfPrimaryBackground.js";
import Testimonial from "./components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import FAQ from "./components/faqs/SingleCol.js";
import GetStarted from "./components/cta/GetStarted";
import Footer from "./components/footers/MiniCenteredFooter";
import Images from "./Images";
import { BiTimer, BiMessageSquareCheck } from "react-icons/bi";
import { HiOutlineArrowsExpand } from "react-icons/hi";
// import { LuMessageSquarePlus } from "react-icons/lu";

/* Hero */
const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between`;
const NavLink = tw.a`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 mr-12 text-gray-700 border-gray-400 hocus:border-gray-700`;
const PrimaryNavLink = tw(
  NavLink
)`text-gray-100 bg-primary-500 px-6 py-3 border-none rounded hocus:bg-primary-900 focus:shadow-outline mt-6 md:mt-4 lg:mt-0`;
const HeroRow = tw(
  Row
)`flex-col lg:flex-row justify-between items-center pt-8 lg:pt-12 pb-16 max-w-screen-2xl mx-auto flex-wrap`;

const Column = tw.div`flex-1`;

const UpdateNotice = tw(
  Column
)`w-full flex-auto mb-4 sm:mb-8 rounded px-4 py-3 sm:px-5 sm:py-4 bg-orange-100 text-orange-800 flex items-center sm:items-start md:items-center justify-center lg:justify-start border border-orange-200 text-xs sm:text-sm text-center sm:text-left md:leading-none`;
const UpdateNoticeIcon = tw(RadioIcon)`w-0 sm:w-5 sm:mr-3`;

const TextColumn = tw(
  Column
)`mx-auto lg:mr-0 max-w-2xl lg:max-w-xl xl:max-w-2xl flex-shrink-0`;
const Heading = tw(
  HeadingBase
)`text-center lg:text-left text-primary-900 leading-snug`;
const Description = tw(
  DescriptionBase
)`mt-4 text-center lg:text-left lg:text-base text-gray-700 max-w-lg mx-auto lg:mx-0`;
const Actions = tw.div`flex flex-col sm:flex-row justify-center lg:justify-start`;
const ActionButton = tw(
  AnchorLink
)`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-12 inline-block tracking-wide text-center px-10 py-4 font-semibold tracking-normal`;
const PrimaryButton = tw(ActionButton)``;
const SecondaryButton = tw(
  ActionButton
)`mt-6 sm:mt-12 sm:ml-8 bg-gray-300 text-gray-800 hocus:bg-gray-400 hocus:text-gray-900`;
const FeatureList = tw.ul`mt-6 leading-loose flex flex-wrap max-w-xl mx-auto lg:mx-0`;
const Feature = tw.li`mt-2 flex items-center flex-shrink-0 w-full sm:w-1/2 justify-center lg:justify-start`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;
const ImageColumn = tw(Column)`mx-auto lg:mr-0 relative mt-16 lg:mt-0 lg:ml-8`;
const ImageContainer = tw.div``;
const Image = tw.img`max-w-full rounded-t sm:rounded`;

const CustomersLogoStrip = styled.div`
  ${tw`mt-12 lg:mt-20 max-w-4xl mx-auto text-center`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`w-full`}
  }
`;

const SectionContainer = tw(ContentWithVerticalPadding)``;
const SectionHeading = tw(HeadingBase)``;
const SectionDescription = tw(
  DescriptionBase
)`text-center mx-auto text-gray-600 max-w-4xl`;

const PreviewCards = tw.div`flex flex-wrap -mr-12`;
const PreviewCardContainer = tw.div`mt-24 mx-auto md:mx-0 max-w-lg w-full md:w-1/2 lg:w-1/3 pr-12`;
const PreviewCard = tw(motion.a)`block rounded-lg shadow-raised`;
const PreviewCardImageContainer = tw.div`rounded-t-lg border-0 border-b-0`;
const PreviewCardImage = styled(motion.div)`
  ${(props) =>
    css`
      background-image: url("${props.$imageSrc}");
    `}
  ${tw`h-128 md:h-144 bg-cover bg-left-top`}
`;
const PreviewButton = tw(
  PrimaryButtonBase
)`w-full rounded-b-lg rounded-t-none py-5 font-semibold`;

const ComponentsContainer = tw.div`mt-24`;
const ComponentsType = tw.h3`text-4xl font-black text-primary-500 border-b-4 border-primary-500 inline-block`;
const Components = tw.div``;
const Component = tw.div`mt-12 border rounded-lg bg-white`;
const ComponentHeading = tw.div`px-8 py-5 border-b flex flex-col sm:flex-row justify-between items-center`;
const ComponentName = tw.h6`text-lg`;
const ComponentPreviewLink = tw.a`mt-4 sm:mt-0 text-primary-500 hocus:text-primary-900 transition duration-300 font-semibold flex items-center`;
const ComponentContent = tw.div`flex justify-between overflow-hidden rounded-b-lg bg-gray-600 relative`;
const ResizableBox = styled(Rnd)`
  ${tw`relative! bg-white pr-4`}
  .resizeHandleWrapper > div {
    ${tw`w-4! right-0!`}
  }
`;
const ResizeHandleButton = tw.button`cursor-col-resize focus:outline-none w-4 border-l bg-gray-100 absolute right-0 inset-y-0`;

const HighlightedText = tw.span`text-primary-500`;
const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
const MaxWidth = styled.div`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
`;

export default ({
  features = null,
  primaryButtonUrl = "#landingPageDemos",
  primaryButtonText = "Explore Demos",
  secondaryButtonUrl = "#componentDemos",
  secondaryButtonText = "View Components",
  buttonRoundedCss = "",
  landingPages,
  innerPages,
  blocks,
}) => {
  const [user, setUser] = useState(false);

  /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
   */
  useEffect(() => {
    window.gtag("js", new Date());
    window.gtag("config", "UA-45799926-9");
  }, []);

  const previewImageAnimationVariants = {
    rest: {
      backgroundPositionY: "0%",
    },
    hover: {
      backgroundPositionY: "100%",
      transition: { type: "tween", ease: "linear", duration: 5 },
    },
  };
  // BiTimer
  // HiOutlineArrowsExpand
  // TbMessageBolt

  const featureCards = [
    {
      icon: <HiOutlineArrowsExpand />,
      title: "Multi-Purpose Messaging",
      description:
        "Send all kinds of messages to as many people as possible, quickly and effortlessly.",
    },
    {
      icon: <BiMessageSquareCheck />,
      title: "Omni-Channel Communication",
      description: "Pick SMS for the best method to broadcast your message.",
    },
    {
      icon: <BiTimer />,
      title: "Quick & Easy Setup",
      description:
        "Sign up for a free account and send your first message blast within seconds!",
    },
  ];

  const Steps = [
    {
      heading: "Upload Your Contact List",
      description:
        "Upload or enter a list of phone numbers, or import contacts via your CRM.",
    },
    {
      heading: "Compose Your Message",
      description:
        "Choose a channel (SMS) and write or vocalize an original message or select a pre-made template.",
    },
    {
      heading: "Mass Send Your Message",
      description:
        "Hit “send” to send your message immediately, or schedule a specific time to send out your message automatically.",
    },
  ];
  return (
    <AnimationRevealPage>
      <MaxWidth>
        <Hero roundedHeaderButton={true} user={user} />

        {/* <div>
          <CustomersLogoStrip>
            <SectionHeading>
              Our Trusted<span tw="text-primary-900"> Customers</span>
            </SectionHeading>
            <img src={Images.PartnersLogos} alt="Our Customers" />
          </CustomersLogoStrip>
        </div> */}

        <Features
          heading={
            <>
              Reasons To Choose <HighlightedText>SMS GATEWAY.</HighlightedText>
            </>
          }
          description={
            "SMS GATEWAY is the most effective online mass communication platform for organizations in any industry."
          }
          cards={featureCards}
        />

        {/* <Stats /> */}

        <Pricing
          subheading={<Subheading>Pricing</Subheading>}
          // heading={<>Reasonable & Flexible Plans</>}
        />

        <Testimonial
          subheading={<Subheading>Testimonials</Subheading>}
          heading={
            <>
              Our Awesome <HighlightedText>Customers.</HighlightedText>
            </>
          }
          testimonials={[
            {
              imageSrc: Images.TestimonialLogo1,
              quote:
                "On a scale of 1 to 100, with one being the lowest satisfaction and 100 being maximum satisfaction, I would give SMS GATEWAY a rating of 90. When we started using SMS GATEWAY, our marketing improved significantly.",
              customerName: "James",
              customerTitle: "Marketing Manager at Tang Palace Hotel",
            },
            {
              imageSrc: Images.TestimonialLogo2,
              quote:
                "Before SMS GATEWAY, Reviv Ghana used to call its clients to tell them about promotions and off days. However, as we grew and the number of clients we cared for increased, calling everyone became impossible. Bulk SMS—a solution from SMS GATEWAY—solved our problem.",
              customerName: "Appiah",
              customerTitle: "Marketing Manager at Reviv Ghana",
            },
            {
              imageSrc: Images.TestimonialLogo3,
              quote:
                "After using the SMS GATEWAY Bulk SMS service, I have been satisfied with the results. On a scale of 1 to 10, I would rate the product an 8 or a 9. However, I would not give the product a perfect rating because it does not deliver SMS internationally. That is something I would like to see implemented on SMS GATEWAY in the future.",
              customerName: "Richard ",
              customerTitle: "IT—CIMAF",
            },
            {
              imageSrc: Images.TestimonialLogo4,
              quote:
                "Fidelio Savings is a micro-finance organization; therefore, we needed to keep our customers updated about the transactions happening in their accounts. We wanted them to be sure that when they transferred money into their account, it did not end up somewhere else. To make all this possible, we started using SMS GATEWAY bulk SMS in 2019. We have been able to keep our customers updated and are satisfied with the SMS GATEWAY Bulk SMS services.",
              customerName: "Jenny",
              customerTitle: "Fidelio Savings",
            },
          ]}
        />

        <GetStarted />
      </MaxWidth>

      <Footer maxWidth={1200} />
    </AnimationRevealPage>
  );
};
