import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/login-illustration.svg";
import logo from "images/logo.svg";
import googleIconImageSrc from "images/google-icon.png";
import twitterIconImageSrc from "images/twitter-icon.png";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import Images from "Images";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import Modal from "react-modal";
import axios from "axios";
import Config from "Config";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";
import { MdOutlineEmail } from "react-icons/md";

const Container = tw(
  ContainerBase
)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div` h-screen bg-white text-gray-900 shadow sm:rounded-lg flex justify-center  `;
const MainContainer = tw.div`lg:w-1/2 xl:w-4/12 py-10 px-12 `;
const LogoLink = tw.a``;
const LogoImage = tw.img`w-32  mb-4`;
const MainContent = tw.div`mt-6 flex flex-col w-11/12 `;
const Heading = tw.h1`text-2xl flex justify-center  font-bold`;
const SubHeading = tw.h1`text-sm font-normal text-center text-gray-600 mt-2`;
const FormContainer = tw.div`w-full flex-1 `;
const Label = tw.div` mt-4 text-gray-800 text-sm`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 -translate-x-1/2 absolute  top-1/2 left-1/2  whitespace-nowrap z-10`;

const Form = tw.form`mx-auto `;
const Input = tw.input`w-full px-4 py-2 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-6 `;
const SubmitButton = styled.button`
  ${tw`w-full mt-6 tracking-wide font-semibold  bg-primary-500 text-gray-100  py-3 rounded-md hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw``}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg overflow-hidden  bg-purple-100 text-center hidden lg:flex flex-1 `;
const IllustrationImage = styled.div`
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw` w-full h-full  bg-center bg-cover  bg-no-repeat`}
`;

export default ({
  logoLinkUrl = "/",
  illustrationImageSrc = illustration,
  headingText = "Log In",
  socialButtons = [
    {
      iconImageSrc: googleIconImageSrc,
      text: "Log In With Google",
      url: "https://google.com",
    },
    {
      iconImageSrc: Images.Fb,
      text: "Log In With Facebook",
      url: "https://www.facebook.com",
    },
    {
      iconImageSrc: twitterIconImageSrc,
      text: "Log In With Twitter",
      url: "https://twitter.com",
    },
  ],
  submitButtonText = "Log In",
  SubmitButtonIcon = LoginIcon,
  forgotPasswordUrl = "#",
  signupUrl = "/sign-up",
}) => {
  const [forgotPassword, setForgotPassword] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [formVal, setFormVal] = useState({
    emailMobile: "",
    password: "",
  });

  const responseGoogle = (res) => {
    // console.log("google", res);
    if (res?.tokenId) {
      loginUser("2", res?.googleId);
    }
  };
  const responseFacebook = (res) => {
    // console.log("fb", res);

    if (res?.id) {
      loginUser("3", res?.id);
    }
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setFormVal((prev) => ({ ...prev, [name]: value }));
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (forgotPassword) {
      forgotPasswordApi();
    }

    //Login
    else {
      if (formVal.password.length < 8) {
        return toast.error("Password should be atleast 8 characters");
      }
      loginUser();
    }
  };

  const loginUser = async (mode, loginId) => {
    try {
      if (!loginLoading) {
        setLoginLoading(true);
        const res = await axios.post(`${Config.baseUrl}/customer/login`, {
          loginId: loginId || formVal?.emailMobile,
          password: formVal?.password || "",
          mode: mode || "1",
        });
        //console.log(res);
        setLoginLoading(false);

        localStorage.setItem("user", JSON.stringify(res?.data));
        window.location = Config.cmsUrl + "/cms";
      }
    } catch (error) {
      // console.log("register user error: ", error?.response);
      setLoginLoading(false);
      toast.error(
        error?.response?.data?.msg ||
          "Something went wrong! please try again later."
      );
    }
  };

  const forgotPasswordApi = async () => {
    try {
      if (!loginLoading) {
        setLoginLoading(true);
        const res = await axios.post(
          `${Config.baseUrl}/customer/forgetPassword`,
          {
            email: formVal?.emailMobile,
          }
        );

        setLoginLoading(false);
        toast.success(res?.data?.msg || "Success");
      }
      // } catch (error) {
      //   // console.log("register user error: ", error?.response);
      //   setLoginLoading(false);

      //   toast.error(
      //     error?.response?.data?.msg ||
      //       "Something went wrong! please try again later."
      //   );
      // }
    } catch (error) {
      setLoginLoading(false);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        toast.error(error.response.data.msg || "An error occurred");
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser
        // console.log(error.request);
        toast.error("No response received from the server");
      } else {
        // Something happened in setting up the request that triggered an Error
        // console.log("Error", error.message);
        toast.error("An error occurred");
      }
    }
  };

  return (
    //  <AnimationRevealPage>
    //   <Container style={{ backgroundImage: `url("/images/sign-up-bg.svg")` }}>
    <>
      <Content>
        <MainContainer>
          {/* <LogoLink href={logoLinkUrl}>
            <LogoImage src={Images.Logo} />
          </LogoLink> */}
          <MainContent>
            <Heading>{forgotPassword ? "Forgot Password" : "Sign In"}</Heading>
            <SubHeading>
              {forgotPassword
                ? "Welcome to SMS GATEWAY, Please enter your registered email to reset your password"
                : "Welcome to SMS GATEWAY, Please put your login credentials below to start using the app"}
            </SubHeading>
            <FormContainer>
              <Form onSubmit={onSubmitHandler}>
                {!forgotPassword && (
                  <>
                    {/* <div className="flex flex-row items-center "> */}
                    {/* <Label>Registered Email</Label> */}
                    <Input
                      type="text"
                      placeholder="Registered Email"
                      value={formVal.emailMobile}
                      name="emailMobile"
                      required
                      onChange={onChangeHandler}
                    />
                    {/* </div> */}
                    {/* <div className="flex flex-row items-center"> */}
                    {/* <Label>Password</Label> */}
                    <Input
                      type="password"
                      placeholder="Password"
                      value={formVal.password}
                      name="password"
                      required
                      onChange={onChangeHandler}
                    />
                    {/* </div> */}
                  </>
                )}
                {!forgotPassword && (
                  <p
                    tw="mt-6 text-xs text-primary-600 text-end cursor-pointer hover:underline"
                    onClick={() => setForgotPassword(true)}
                  >
                    Forgot Password ?
                  </p>
                )}
                {forgotPassword && (
                  // <div className="flex flex-row">
                  <>
                    {/* <Label>Registered Email</Label> */}
                    <Input
                      type="text"
                      placeholder="Enter your account-linked email"
                      value={formVal.emailMobile}
                      name="emailMobile"
                      required
                      onChange={onChangeHandler}
                    />
                  </>
                  // </div>
                )}
                {/* <div className="flex flex-row"> */}

                <SubmitButton type="submit" disabled={loginLoading}>
                  {/* <SubmitButtonIcon className="icon" /> */}
                  {!loginLoading && (
                    <span className="text text-sm">
                      {forgotPassword ? "Reset Password" : "Sign In"}
                    </span>
                  )}
                  {loginLoading && (
                    <ReactLoading
                      type={"spin"}
                      color={"white"}
                      height={20}
                      width={20}
                    />
                  )}
                </SubmitButton>
                <p tw="mt-6 text-sm text-gray-600 text-center  ">
                  Don't have an account?{" "}
                  <a
                    href={signupUrl}
                    tw="border-b border-primary-500 text-primary-600 border-dotted"
                  >
                    Sign Up
                  </a>
                </p>
                {/* </div> */}
              </Form>

              {/* {!forgotPassword && (
                  <>
                    <DividerTextContainer>
                      <DividerText>
                        Or log in with your social accounts
                      </DividerText>
                    </DividerTextContainer>

                    <SocialButtonsContainer>
                      <GoogleLogin
                        clientId="201183790851-piovcqonvpe0b1gg8d3vv9ekn95msjal.apps.googleusercontent.com"
                        buttonText="Login"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        render={(renderProps) => (
                          <SocialButton
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                          >
                            <span className="iconContainer">
                              <img
                                src={socialButtons[0].iconImageSrc}
                                className="icon"
                                alt=""
                              />
                            </span>
                            <span className="text">
                              {socialButtons[0].text}
                            </span>
                          </SocialButton>
                        )}
                        cookiePolicy={"single_host_origin"}
                      /> 

                      <FacebookLogin
                        appId="583732173579429"
                        fields="name,email,picture"
                        callback={responseFacebook}
                        render={(renderProps) => (
                          <SocialButton
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                          >
                            <span className="iconContainer">
                              <img
                                src={socialButtons[1].iconImageSrc}
                                className="icon"
                                alt=""
                              />
                            </span>
                            <span className="text">
                              {socialButtons[1].text}
                            </span>
                          </SocialButton>
                        )}
                      />
                    </SocialButtonsContainer>
                  </>
                )} */}
            </FormContainer>
          </MainContent>
        </MainContainer>
        <IllustrationContainer>
          <IllustrationImage imageSrc={Images.LoginSideImage} />
        </IllustrationContainer>
      </Content>
      {/* </Container> */}
      {/* </AnimationRevealPage> */}
    </>
  );
};
