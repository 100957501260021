import React, { useRef, useState } from "react";
import Images from "../Images";
import tw, { styled } from "twin.macro";
import Config from "../Config";
import axios from "axios";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/bundle";
import { useMutation } from "react-query";
import Loading from "../components/Loading";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdEmail, MdPassword } from "react-icons/md";

const LoginPage = () => {
  const data = [
    {
      id: 1,
      name: "Your Centralized Messaging Solution",
      img: Images.RedBg,
      zoner:
        "Manage all your SMS communications from a single hub, ensuring streamlined and organized conversations. ",
    },
    {
      id: 2,
      name: "Reach Your Audience with SMS",
      img: Images.BlackBg,
      zoner:
        "InstantReach is a dynamic SMS sending platform that enables you to instantly reach your audience. ",
    },
    {
      id: 3,
      name: "Instant Communication Made Easy",
      img: Images.BlueBg,
      zoner:
        "Stay connected and engage with your audience in an instant, whether it's for important announcements, promotions, or general communication.",
    },
    {
      id: 4,
      name: "Bridge the Communication Gap with SMS",
      img: Images.PurpleBg,
      zoner:
        "Seamlessly connect with your audience, customers, or community through personalized text messages.",
    },
  ];

  const loginFunction = async (body) => {
    return await axios.post(`${Config.baseUrl}/customer/login`, body, {
      headers: {
        "Content-Type": "application/json ",
      },
    });
  };

  const onloginSuccess = (data) => {
    localStorage.setItem("user", JSON.stringify(data?.data));
    window.location = Config.cmsUrl + "/cms";
  };

  const onloginError = (err) => {
    toast.error(err.response.data.msg || "An Error Occured", {
      position: toast.POSITION.TOP_LEFT,
    });
  };

  const { isLoading: loginLoading, mutate: loginMutate } = useMutation(
    loginFunction,
    {
      onSuccess: onloginSuccess,
      onError: onloginError,
    }
  );

  const initialValues = {
    loginId: "",
    password: "",
  };

  const submitHandler = (values) => {
    const body = new FormData();
    if (values.password.length < 8) {
      return toast.error("Password should be atleast 8 characters", {
        position: toast.POSITION.TOP_LEFT,
      });
    }
    body.append("loginId", values.userName);
    body.append("password", values.password);
    body.append("mode", "1");

    loginMutate(body);
   
  };


  return (
    <Login>
      <TextWrapperComp
        initialValues={initialValues}
        submitHandler={submitHandler}
        loginLoading={loginLoading}
      />
      <ImageWrapper>
        <CarouselContainer data={data} />
      </ImageWrapper>
    </Login>
  );
};
const TextWrapperComp = ({
  initialValues,
  submitHandler,
  loginLoading,
}) => (
  <TextWrapper>
    <Container className="Container">
      <LogoHeading>
        <Link to="/">
          <Logo src={Images.Logo} alt="rapid test and trace logo" />
        </Link>

      </LogoHeading>

      <Center>
        <Formik initialValues={initialValues} onSubmit={submitHandler}>
          <Form>
            <FormContainer>
              <Title>Login</Title>
              <Desc>To your account</Desc>
              <InputGroup>
                <FieldWrapper>
                  <MailIcon className="icon" />
                  <Field
                    name="userName"
                    type="email"
                    id="userName"
                    required
                    autoComplete="off"
                    placeholder="Email address"
                    className="loginInput"
                    style={{
                      marginLeft: 32,
                      outline: "none",
                      borderRadius: "0px",
                    }}
                  ></Field>
                </FieldWrapper>
              </InputGroup>

              <InputGroup>
                <FieldWrapper>
                  <PassIcon className="icon" />
                  <Field
                    name="password"
                    type="password"
                    id="password"
                    required
                    autoComplete="off"
                    placeholder="Password"
                    className="loginInput"
                    style={{
                      marginLeft: 32,
                      outline: "none",
                      borderRadius: "0px",
                      // paddingLeft: 20,
                    }}
                  />
                </FieldWrapper>
                <ForgotPassword>
                  <Link to="/forgetpassword">Forgot Password?</Link>
                </ForgotPassword>
              </InputGroup>
              <div>
                <SubmitButton type="submit" disabled={loginLoading}>
                  {loginLoading ? (
                    <Loading
                      color="white"
                      width={20}
                      height={20}
                      noPadding={true}
                    />
                  ) : (
                    "Login"
                  )}
                </SubmitButton>
              </div>
            </FormContainer>
          </Form>
        </Formik>
      </Center>

      <BottomText>
        <p>Don't have an account?</p>
        <Link to="/sign-up">
          <SignUp>Sign up</SignUp>
        </Link>
      </BottomText>
    </Container>
  </TextWrapper>
);

const CarouselContainer = ({ data }) => {
  return (
    <SwiperGroup className="home-carousel">
      <Swiper
        className="h-screen"
        modules={[Navigation, Autoplay, Pagination]}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        slidesPerView={1}
      >
        {data.map((value) => {
          const { id, name, img, zoner } = value;
          return (
            <SwiperSlide
              key={id}
              tw="relative h-screen border-2 border-blue-500"
            >
              <Image src={img} alt={name} />
              <TextOverlay>
                <OverlayTitle>{name}</OverlayTitle>
                <OverlayText> {zoner}</OverlayText>
              </TextOverlay>
            </SwiperSlide>
          );  
        })}
      </Swiper>
    </SwiperGroup>
  );
};

const Login = tw.section`h-screen overflow-hidden md:flex`;
const Logo = tw.img`h-8 w-auto mx-auto md:mx-0 mb-10 mt-8`;
const LogoHeading = tw.div`flex justify-start mt-5`;
const Heading = tw.h1`text-xl ml-6 text-black`;
const Container = tw.div`flex flex-col`;
const TextWrapper = tw.div`h-full w-full md:w-6/12 lg:w-4/12 relative md:overflow-x-hidden overflow-y-auto md:pl-8`;
const ImageWrapper = tw.div`hidden md:block md:w-6/12 lg:w-8/12 h-full`;
const Center = tw.div`grow flex flex-col`;
const Title = tw.h1`text-2xl text-black font-bold `;
const Desc = tw.h1`text-sm text-gray-600 font-normal mb-10`;
const SubmitButton = tw.button`w-full text-sm md:text-base  h-12 text-white grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed  !bg-primary-500  rounded-full shadow-md hover:shadow-lg mt-10`;
const BottomText = tw.p`text-sm mb-5 text-gray-800 flex justify-start`;
const ForgotPassword = tw.p`text-primary-500 text-sm ml-1  flex items-center justify-end`;
const SwiperGroup = tw.div` h-full overflow-hidden shadow-xl mx-auto md:w-full md:h-full opacity-100`;
const InputGroup = tw.div`w-full flex flex-col space-y-2 mt-5`;
// const FieldWrapper = tw.div`h-10 overflow-hidden border border-gray-700 relative rounded-full w-full flex items-center`;
const Image = tw.img`cursor-pointer object-cover w-full h-full`;
const TextOverlay = tw.div`hidden md:block md:absolute md:top-1/2 md:left-0 md:w-full md:h-full px-10 transform -translate-y-32 `;
const OverlayTitle = tw.h2` md:text-4xl md:w-10/12 lg:text-5xl lg:w-7/12 text-white md:leading-tight font-bold`;
const OverlayText = tw.p`text-base mt-2 text-white md:w-10/12 lg:w-6/12 mt-5 leading-relaxed`;
const FormContainer = tw.div`mt-16 mr-6`;
const SignUp = tw.p`text-primary-500 font-medium ml-1`;

const FieldWrapper = styled.div`
  ${tw`h-10 overflow-hidden border border-gray-700 relative rounded-full w-full flex items-center`}
`;
// const FieldWrapper = styled.div`
//   ${tw`h-10 relative rounded-full w-full `}
// `;
// const MailIcon = tw(
//   MdEmail
// )`text-gray-600 absolute right-0 top-1/2 transform -translate-y-1/2`;

const MailIcon = styled(MdEmail)`
  ${tw`text-gray-600 absolute ml-4 top-1/2 transform -translate-y-1/2`}
`;

const PassIcon = styled(MdPassword)`
  ${tw`text-gray-600 absolute ml-4 top-1/2 transform -translate-y-1/2`}
`;
const Input = styled.input`
  ${tw`pl-10 pr-3 py-2 rounded-full w-full focus:outline-none focus:ring-2 focus:ring-blue-500`}
`;

export default LoginPage;
