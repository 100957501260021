import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import smsImage6 from "images/smsimage6.svg";
import ReactLoading from "react-loading";
import Images from "../../Images.js";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border border-gray-500 py-2 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24 mt-6 border border-gray-500 rounded-lg p-2`}
`;

const SubmitButton = tw(
  PrimaryButtonBase
)`inline-block mt-8 flex justify-center items-center rounded-full`;

export default ({
  subheading = "Contact Us",
  heading = (
    <>
      Feel free to <span tw="text-primary-500">get in touch</span>
      <wbr /> with us.
    </>
  ),
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  submitButtonText = "Send",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
  formVal,
  changeHandler,
  submitHandler,
  isLoading,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          {/* <Image imageSrc={EmailIllustrationSrc} /> */}
          <Image imageSrc={smsImage6} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && (
              <Description>
                You can directly mail your query to{" "}
                <a
                  tw="!text-gray-600 !underline hover:!text-primary-500 "
                  href="mailto:info@think-straight.com"
                >
                  info@think-straight.com
                </a>
                <br />
                or call on{" "}
                <a tw="!text-gray-600 hover:underline" href="tel:+919958159213">
                  +91 995 815 9213
                </a>{" "}
              </Description>
            )}
            <Form onSubmit={submitHandler}>
              <Input
                type="email"
                name="email"
                placeholder="Your Email Address"
                value={formVal.email}
                onChange={changeHandler}
                required
              />
              <Input
                type="text"
                name="name"
                placeholder="Full Name"
                value={formVal.name}
                onChange={changeHandler}
                required
              />
              <Input
                type="text"
                name="subject"
                placeholder="Subject"
                value={formVal.subject}
                onChange={changeHandler}
                required
              />
              <Textarea
                name="message"
                placeholder="Your Message Here"
                value={formVal.message}
                onChange={changeHandler}
                required
              />
              <SubmitButton type="submit">
                {!isLoading && submitButtonText}
                {isLoading && (
                  <ReactLoading
                    type={"spin"}
                    color={"white"}
                    height={20}
                    width={20}
                  />
                )}
              </SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
