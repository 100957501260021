import React, { useRef, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-lines
import googleIconImageSrc from "images/google-icon.png";
import twitterIconImageSrc from "images/twitter-icon.png";
import { ReactComponent as SignUpIcon } from "feather-icons/dist/icons/user-plus.svg";
import Images from "Images";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import axios from "axios";
import Config from "Config";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";

const Container = tw(
  ContainerBase
)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`h-screen  bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-4/12 py-10 px-12 `;
const LogoLink = tw.a``;
const LogoImage = tw.img`w-32 mx-auto `;
const MainContent = tw.div`mt-6 flex flex-col w-11/12`;
const Heading = tw.h1`text-3xl  font-bold flex  space-x-1 flex justify-center cursor-pointer`;
const FormContainer = tw.div`w-full flex-1 `;
const FormContainerHorizontal = tw.div` grid md:grid-cols-2  gap-8 `;
const Label = tw.div`mt-3  text-gray-800 text-sm`;
const SubHeading = tw.h1`text-sm font-normal text-gray-600 text-center mt-1 `;
const SocialButtonsContainer = tw.div`flex flex-col items-center mt-2`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs cursor-pointer font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-4 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 -translate-x-1/2 absolute  top-1/2 left-1/2  whitespace-nowrap z-10`;
const Input = tw.input`w-full px-4 py-2 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-6 `;
const Form = tw.form`mx-auto `;
// const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const OtpInput = tw.input`w-full w-12  text-center py-4 rounded-lg font-medium bg-gray-100 border border-gray-300 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white `;
const SubmitButton = styled.button`
  ${tw`mt-6 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full h-12 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw``}
  }
`;
// const SubmitButton = styled.button`
//   ${tw`mt-4 tracking-wide font-semibold  bg-primary-500 text-gray-100  py-2 rounded-md hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
//   .icon {
//     ${tw`w-6 h-6 -ml-2`}
//   }
//   .text {
//     ${tw``}
//   }
// `;
const IllustrationContainer = tw.div`sm:rounded-r-lg overflow-hidden  bg-purple-100 text-center hidden lg:flex flex-1 `;
const IllustrationImage = styled.div`
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw` w-full h-full bg-cover bg-center  bg-no-repeat`}
`;

const ErrorText = tw.p`w-full text-red-600 text-xs mt-1`;

const OtpInputWrapper = tw.div`w-full flex justify-between items-center mt-6`;

export default ({
  logoLinkUrl = "/",
  headingText = "Sign Up",
  socialButtons = [
    {
      iconImageSrc: googleIconImageSrc,
      text: "Sign Up With Google",
      url: "https://google.com",
    },
    {
      iconImageSrc: Images.Fb,
      text: "Sign Up With Facebook",
      url: "https://www.facebook.com",
    },
    {
      iconImageSrc: twitterIconImageSrc,
      text: "Sign Up With Twitter",
      url: "https://twitter.com",
    },
  ],
  submitButtonText = "Sign Up",
  SubmitButtonIcon = SignUpIcon,
  tosUrl = "#",
  privacyPolicyUrl = "#",
  signInUrl = "/login",
}) => {
  const firstOTP = useRef(null);
  const secondOTP = useRef(null);
  const thirdOTP = useRef(null);
  const fourthOTP = useRef(null);
  const [mode, setMode] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [isPasswordSame, setIsPasswordSame] = useState(true);
  const [registerLoading, setRegisterLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [formVal, setFormVal] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
    confirmPassword: "",
    mode: "",
    // socialId: "",
  });
  const [otpVal, setOtpVal] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
  });

  const responseGoogle = (res) => {
    // console.log("google", res);
    if (res?.tokenId) {
      setMode("google");
      setFormVal({
        name: res?.profileObj?.givenName,
        email: res?.profileObj?.email,
        mobile: "",
        password: "",
        confirmPassword: "",
        mode: 2,
        socialId: res?.googleId,
      });
    }
  };
  const responseFacebook = (res) => {
    // console.log("fb", res);

    if (res?.id) {
      setMode("facebook");

      setFormVal({
        name: res?.name,
        email: res?.email,
        mobile: "",
        password: "",
        confirmPassword: "",
        mode: 3,
        socialId: res?.id,
      });
    }
  };

  const goBack = () => {
    if (mode) {
      setMode("");
      setShowOtp(false);
      setOtpVal({
        first: "",
        second: "",
        third: "",
        fourth: "",
      });
      setFormVal({
        name: "",
        email: "",
        mobile: "",
        password: "",
        confirmPassword: "",
        mode: "",
        // socialId: "",
      });
    }
  };

  const changeHandler = (e) => {
    const { value, name } = e.target;

    if (name == "mobile" && value.length > 9) return;

    setFormVal((prev) => ({ ...prev, [name]: value }));
  };

  const OtpChangeHandler = (e) => {
    const { value, name } = e.target;
    if (value) {
      const length = value.length;
      if (value.length === 1) {
        setOtpVal((prev) => ({ ...prev, [name]: value }));
        if (name == "first") secondOTP.current.focus();
        else if (name == "second") thirdOTP.current.focus();
        else if (name == "third") fourthOTP.current.focus();
      }
    } else {
      setOtpVal((prev) => ({ ...prev, [name]: value }));
      if (name == "fourth") thirdOTP.current.focus();
      else if (name == "third") secondOTP.current.focus();
      else if (name == "second") firstOTP.current.focus();
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (!showOtp) {
      if (formVal.mobile.length != 9) {
        return toast.error("Please enter your valid 9 digit phone number");
      } else if (formVal.password.length < 8) {
        return toast.error("Password should be atleast 8 characters");
      } else if (formVal.password !== formVal.confirmPassword) {
        return toast.error("Password and Confirm Password should be same");
      }

      setIsPasswordSame(true);
      registerUser();
    }

    //OTP
    else {
      VerifyOtp();
    }
  };

  const registerUser = async () => {
    try {
      if (!registerLoading && !otpLoading) {
        setRegisterLoading(true);
        const res = await axios.post(`${Config.baseUrl}/customer/signUp`, {
          mode: formVal?.mode || mode,
          password: formVal?.password,
          mobilenumber: formVal?.mobile,
          // socialId: formVal?.socialId,
          name: formVal?.name,
          email: formVal?.email,
        });

        setShowOtp(true);
        toast.success(res.data.msg || "Success");
        setRegisterLoading(false);
      }
    } catch (error) {
      // console.log("register user error: ", error?.response);
      setRegisterLoading(false);
      toast.error(
        error?.response?.data?.msg ||
          "Something went wrong! please try again later."
      );
    }
  };

  const VerifyOtp = async () => {
    try {
      if (!registerLoading && !otpLoading) {
        setOtpLoading(true);
        const res = await axios.post(`${Config.baseUrl}/customer/otpVerify`, {
          mobilenumber: formVal?.mobile,
          email: formVal?.email,
          otp: otpVal?.first + otpVal?.second + otpVal?.third + otpVal?.fourth,
        });
        // console.log(res);

        setShowOtp(true);
        // toast.success(res.data.msg || "Success");
        setOtpLoading(false);

        localStorage.setItem("user", JSON.stringify(res?.data));
        window.location = Config.cmsUrl + "/cms";
      }
    } catch (error) {
      // console.log("Verify OTP: ", error?.response);
      setOtpLoading(false);
      toast.error(
        error?.response?.data?.msg ||
          "Something went wrong! please try again later."
      );
    }
  };

  return (
    // <AnimationRevealPage>
    //   <Container style={{ backgroundImage: ` url("/images/sign-up-bg.svg") ` }}>
    <Content>
      <MainContainer>
        {/* <LogoLink href={logoLinkUrl}>
          <LogoImage src={Images.Logo} />
        </LogoLink> */}
        <MainContent>
          <Heading onClick={goBack}>
            {/* {mode && <IoIosArrowBack className="w-3 h-3  text-black" />} */}
            <p>{headingText}</p>
          </Heading>
          <SubHeading>
            Get access to exclusive features by creating an account
          </SubHeading>
          <FormContainer>
            {mode && (
              <>
                <Form onSubmit={submitHandler} method="POST">
                  {!showOtp && (
                    <>
                      {/* <FormContainerHorizontal> */}
                      {/* <div className="flex flex-row items-center "> */}
                      <div>
                        {/* <Label>Name</Label> */}
                        <Input
                          type="text"
                          placeholder="Name"
                          name="name"
                          value={formVal.name}
                          required
                          onChange={changeHandler}
                        />
                      </div>
                      {/* </div> */}
                      {/* <div className="flex flex-row items-center "> */}
                      <div>
                        {/* <Label>Email</Label> */}
                        <Input
                          type="email"
                          placeholder="Email"
                          name="email"
                          value={formVal.email}
                          required
                          onChange={changeHandler}
                        />
                      </div>
                      {/* </div> */}
                      {/* <div className="flex flex-row items-center "> */}
                      <div>
                        {/* <Label>Mobile Number</Label> */}
                        {/* <div tw="w-full relative mt-6">
                        <p
                          tw="absolute top-0 left-0 text-sm pl-6 text-gray-900"
                          style={{ paddingTop: 17 }}
                        >
                          +233
                        </p> */}

                        <Input
                          type="number"
                          placeholder="Mobile Number"
                          name="mobile"
                          value={formVal.mobile}
                          required
                          onChange={changeHandler}
                          // tw="!pl-16  !mt-0"
                        />
                      </div>
                      {/* </div> */}
                      {/* </div> */}
                      {/* <p style={{ fontSize: 10, color: "gray", marginTop: 5 }}>
                        *Please enter mobile number without country code
                      </p> */}
                      {/* <div className="flex flex-row items-center "> */}
                      <div>
                        {/* <Label>Password</Label> */}
                        <Input
                          type="password"
                          placeholder="Password"
                          name="password"
                          value={formVal.password}
                          required
                          onChange={changeHandler}
                        />
                      </div>
                      {/* </div> */}

                      {/* <p
                          style={{ fontSize: 10, color: "gray", marginTop: 5 }}
                        >
                          *Password should be atleast 8 characters
                        </p> */}
                      {/* <div className="flex flex-row items-center "> */}
                      <div>
                        {/* <Label>Confirm Password</Label> */}
                        <Input
                          type="password"
                          placeholder="Confirm Password"
                          name="confirmPassword"
                          value={formVal.confirmPassword}
                          required
                          onChange={changeHandler}
                        />
                      </div>
                      {/* </div> */}
                      {/* </FormContainerHorizontal> */}
                    </>
                  )}

                  {showOtp && (
                    <OtpInputWrapper>
                      <OtpInput
                        type="number"
                        maxLength={1}
                        name="first"
                        value={otpVal.first}
                        required
                        onChange={OtpChangeHandler}
                        ref={firstOTP}
                      />
                      <OtpInput
                        type="number"
                        maxLength={1}
                        name="second"
                        value={otpVal.second}
                        required
                        onChange={OtpChangeHandler}
                        ref={secondOTP}
                      />
                      <OtpInput
                        type="number"
                        maxLength={1}
                        name="third"
                        value={otpVal.third}
                        required
                        onChange={OtpChangeHandler}
                        ref={thirdOTP}
                      />
                      <OtpInput
                        type="number"
                        maxLength={1}
                        name="fourth"
                        value={otpVal.fourth}
                        required
                        onChange={OtpChangeHandler}
                        ref={fourthOTP}
                      />
                    </OtpInputWrapper>
                  )}

                  <SubmitButton
                    type="submit"
                    disabled={registerLoading || otpLoading}
                  >
                    {!registerLoading && !otpLoading && (
                      <span className="text">
                        {showOtp ? "Confirm OTP" : "Continue"}
                      </span>
                    )}
                    {/* {!showOtp && !registerLoading && (
                        <IoIosArrowForward className="w-4 h-4 text-white" />
                      )} */}
                    {(registerLoading || otpLoading) && (
                      <ReactLoading
                        type={"spin"}
                        color={"white"}
                        height={20}
                        width={20}
                      />
                    )}
                  </SubmitButton>
                  <p tw="mt-4 text-xs text-gray-600 text-center ">
                    I agree to abide by{" "}
                    <a
                      href={tosUrl}
                      tw="border-b border-primary-500 text-primary-600 border-dotted"
                    >
                      Terms of Service
                    </a>{" "}
                    and its{" "}
                    <a
                      href={privacyPolicyUrl}
                      tw="border-b border-primary-500 text-primary-600 border-dotted"
                    >
                      Privacy Policy
                    </a>
                  </p>
                </Form>
              </>
            )}

            {/* <DividerTextContainer>
                <DividerText>Or sign up with your social accounts</DividerText>
              </DividerTextContainer> */}
            {!mode && (
              <SocialButtonsContainer>
                <SocialButton
                  onClick={() => setMode(1)}
                  style={{ minWidth: 250 }}
                >
                  <span className="iconContainer">
                    <img src={Images.Gmail} className="icon" alt="" />
                  </span>
                  <span className="text">Sign Up with Email</span>
                </SocialButton>

                {/* <GoogleLogin
                      clientId="201183790851-piovcqonvpe0b1gg8d3vv9ekn95msjal.apps.googleusercontent.com"
                      buttonText="Login"
                      onSuccess={responseGoogle}
                      onFailure={responseGoogle}
                      render={(renderProps) => (
                        <SocialButton
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                          style={{ minWidth: 250 }}
                        >
                          <span className="iconContainer">
                            <img
                              src={socialButtons[0].iconImageSrc}
                              className="icon"
                              alt=""
                            />
                          </span>
                          <span className="text">{socialButtons[0].text}</span>
                        </SocialButton>
                      )}
                      cookiePolicy={"single_host_origin"}
                    /> */}

                {/* <FacebookLogin
                      appId="583732173579429"
                      fields="name,email,picture"
                      callback={responseFacebook}
                      render={(renderProps) => (
                        <SocialButton
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                          style={{ minWidth: 250 }}
                        >
                          <span className="iconContainer">
                            <img
                              src={socialButtons[1].iconImageSrc}
                              className="icon"
                              alt=""
                            />
                          </span>
                          <span className="text">{socialButtons[1].text}</span>
                        </SocialButton>
                      )}
                    /> */}
              </SocialButtonsContainer>
            )}
            <p tw="mt-4 text-sm text-gray-600 text-center w-11/12">
              Already have an account?{" "}
              <a
                href={signInUrl}
                tw="border-b border-primary-500 text-primary-600 border-dotted"
              >
                Sign In
              </a>
            </p>
          </FormContainer>
        </MainContent>
      </MainContainer>
      <IllustrationContainer>
        <IllustrationImage imageSrc={Images.SignUpSideImage} />
      </IllustrationContainer>
    </Content>
    //   </Container>
    // </AnimationRevealPage>
  );
};
