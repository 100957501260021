const Images = {
  Logo: "./images/logo.png",
  PartnersLogos: "./images/logos.png",
  LogoWhite: "./images/logo_white.png",
  MultiPurpose: "./images/mulit-purpose.png",
  // MultiPurpose: "./images/mulit-purpose.svg",
  OmniChannel: "./images/omniChannel.png",
  QuickEasy: "./images/quickEasy.png",
  LandingImage: "./images/landing_image.svg",
  LandingImage1: "./images/image.svg",
  Cms: "./images/cms.png",
  LoginSideImage: "./images/login-side.jpg",
  SignUpSideImage: "./images/sign-up-side.jpg",
  Client: "./images/client.jpg",
  Client2: "./images/client2.webp",
  TestimonialLogo1: "./images/potrait.jpg",
  TestimonialLogo2: "./images/potrait3.jpg",
  TestimonialLogo3: "./images/potrait2.jpg",
  TestimonialLogo4: "./images/potrait4.jpg",
  Fb: "./images/fb.png",
  Gmail: "./images/gmail.png",
  HomePage: "./images/homepage.png",
  HomePages: "./images/homepage.svg",
  Message: "./images/Message.png",
  Mass: "./images/Mass.png",
  Masss: "./images/Mass.svg",
  Great_Message: "./images/Great_Message.jpg",
  GreatMessage: "./images/Great_Message.svg",
  YellowBg: "./images/yellowbg.svg",
  PurpleBg: "./images/yellow_newbg.svg",
  RedBg: "./images/darkBluebg.svg",
  BlueBg: "./images/bluebg.svg",
  BlackBg: "./images/blackbg.svg",
  Sms: "./images/download.svg",
  Contact: "./images/smsImage6.svg",
};

export default Images;
