import React, { useState, useEffect } from "react";
import GlobalStyles from "./styles/GlobalStyles";
import MainLandingPage from "./MainLandingPage.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import NewLogin from "./pages/NewLogin";
// import SignUp from "pages/SignUp";
import ContactUs from "./pages/ContactUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import SmsInfo from "./pages/SmsInfo";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Config from "./Config";
import { useDispatch, useSelector } from "react-redux";
import ForgetPage from "pages/ForgetPassword";
import SignUpPage from "pages/SignUpNew";
import OtpPage from "pages/Otp";

export default function App() {
  const [user, setUser] = useState(null);
  // const user = useSelector((state) => state.UserReducer.user);
  useEffect(() => {
    const isUser = localStorage.getItem("user");
    if (isUser && window.location.href != Config.cmsUrl + "/cms") {
      setUser(JSON.parse(isUser));
      window.location = Config.cmsUrl + "/cms";
    }
  }, []);

  return (
    <>
      {!user ? (
        <>
          <GlobalStyles />
          <ToastContainer
            position="top-center"
            autoClose={10000}
            hideProgressBar={true}
            newestOnTop={true}
            closeOnClick
            // rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover={false}
          />

          <Router>
            <Routes>
              <Route path="/" element={<MainLandingPage />} />
              {/* <Route path="/login" element={<Login />} /> */}
              <Route path="/login" element={<NewLogin />} />
              <Route path="/forgetpassword" element={<ForgetPage />} />
              {/* <Route path="/sign-up" element={<Signup />} /> */}
              <Route path="/sign-up" element={<SignUpPage />} />
              <Route path="/otp" element={<OtpPage />} />
              <Route path="/contact" element={<ContactUs />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/products/sms-broadcast" element={<SmsInfo />} />
              <Route path="*" exact={true} element={<MainLandingPage />} />
            </Routes>
          </Router>
        </>
      ) : null}
    </>
  );
}
