import React, { useRef, useState } from "react";
import Images from "../Images";
import tw, { styled } from "twin.macro";
import Config from "../Config";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/bundle";
import { useMutation } from "react-query";
import Loading from "../components/Loading";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HiArrowNarrowRight } from "react-icons/hi";
import { IoIosArrowBack } from "react-icons/io";
import { useMediaQuery } from "react-responsive";
import { MdEmail, MdPassword } from "react-icons/md";

const ForgetPage = () => {
  const data = [
    {
      id: 1,
      name: "Your Centralized Messaging Solution",
      img: Images.RedBg,
      zoner:
        "Manage all your SMS communications from a single hub, ensuring streamlined and organized conversations. ",
    },
    {
      id: 2,
      name: "Reach Your Audience with SMS",
      img: Images.BlackBg,
      zoner:
        "InstantReach is a dynamic SMS sending platform that enables you to instantly reach your audience. ",
    },
    {
      id: 3,
      name: "Instant Communication Made Easy",
      img: Images.BlueBg,
      zoner:
        "Stay connected and engage with your audience in an instant, whether it's for important announcements, promotions, or general communication.",
    },
    {
      id: 4,
      name: "Bridge the Communication Gap with SMS",
      img: Images.PurpleBg,
      zoner:
        "Seamlessly connect with your audience, customers, or community through personalized text messages.",
    },
  ];

  const navigate = useNavigate();
  const captchaRef = useRef(null);
  const [forgotPassword, setForgotPassword] = useState(false);
  // const [loginLoading, setLoginLoading] = useState(false);
  const [formVal, setFormVal] = useState({
    emailMobile: "",
    password: "",
  });

  const forgetFunction = async (body) => {
    return await axios.post(`${Config.baseUrl}/customer/forgetPassword`, body, {
      headers: {
        "Content-Type": "application/json ",
      },
    });
  };

  const onforgetSuccess = (data) => {
    toast.success(data?.data?.msg || "Success", {
      position: toast.POSITION.TOP_LEFT,
    });
  };

  const onforgetError = (err) => {
    toast.error(err.response.data.msg || "An Error Occured", {
      position: toast.POSITION.TOP_LEFT,
    });
  };

  const { isLoading: forgetLoading, mutate: forgetMutate } = useMutation(
    forgetFunction,
    {
      onSuccess: onforgetSuccess,
      onError: onforgetError,
    }
  );

  const initialValues = {
    loginId: "",
  };

  const submitHandler = (values) => {
    const body = new FormData();
    body.append("email", values.userName);
    // body.append("password", values.password);
    // body.append("mode", "1");

    forgetMutate(body);
    // const body = {
    //   loginId: values.userName,
    //   password: values.password,
    //   mode: "1",
    // };

    // loginMutate(JSON.stringify(body));
  };

  function onChange(value) {
    // setCapcha(!isCapchaChecked);
  }
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const marginLeft = isMobile ? -10 : -21;
  return (
    <Login>
      <TextWrapperComp
        initialValues={initialValues}
        submitHandler={submitHandler}
        // alert={alert}
        captchaRef={captchaRef}
        onChange={onChange}
        forgetLoading={forgetLoading}
        isMobile={isMobile}
        marginLeft={marginLeft}
      />
      <ImageWrapper>
        <CarouselContainer data={data} />
      </ImageWrapper>
    </Login>
  );
};
const TextWrapperComp = ({
  initialValues,
  submitHandler,
  onChange,
  loginLoading,
  forgetLoading,
  captchaRef,
  isMobile,
  marginLeft,
}) => (
  <TextWrapper>
    <Container className="Container">
      <LogoHeading>
        <Link to="/">
          <Logo src={Images.Logo} alt="rapid test and trace logo" />
        </Link>

        {/* <Heading> Developer Portal </Heading> */}
      </LogoHeading>

      <Center>
        <Formik initialValues={initialValues} onSubmit={submitHandler}>
          <Form>
            <FormContainer>
              <p
                className="flex flex-row items-center space-x-2"
                // style={{ marginLeft: -21 }}
                style={{ marginLeft: marginLeft }}
              >
                <Link to="/login">
                  <BackArrow>
                    <IoIosArrowBack style={{ width: 20, height: 20 }} />
                  </BackArrow>
                </Link>

                <Title>Forget Password</Title>
              </p>

              <Desc
                style={{
                  marginLeft: isMobile ? 12 : 0,
                }}
              >
                Reset your account
              </Desc>
              <InputGroup>
                <FieldWrapper>
                  <MailIcon className="icon" />
                  <Field
                    name="userName"
                    type="email"
                    id="userName"
                    required
                    autoComplete="off"
                    placeholder="Email address"
                    className="loginInput"
                    style={{
                      marginLeft: 32,
                      outline: "none",
                      borderRadius: "0px",
                    }}
                  />
                </FieldWrapper>
              </InputGroup>

              {/* <InputGroup>
                <FieldWrapper>
                  <Field
                    name="password"
                    type="password"
                    id="password"
                    required
                    autoComplete="off"
                    placeholder="Password"
                  />
                </FieldWrapper>
                <ForgotPassword>
                  <Link to="/forgetpassword">Forgot Password?</Link>
                </ForgotPassword>
              </InputGroup> */}
              <div>
                <SubmitButton type="submit" disabled={forgetLoading}>
                  {forgetLoading ? (
                    <Loading
                      color="white"
                      width={20}
                      height={20}
                      noPadding={true}
                    />
                  ) : (
                    "Reset Password"
                  )}
                </SubmitButton>
              </div>
            </FormContainer>
          </Form>
        </Formik>
      </Center>

      <BottomText>
        <p>Don't have an account?</p>
        <Link to="/sign-up">
          <SignUp>Sign up</SignUp>
        </Link>
      </BottomText>
    </Container>
  </TextWrapper>
);

const CarouselContainer = ({ data }) => {
  return (
    <SwiperGroup className="home-carousel">
      <Swiper
        className="h-screen"
        modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y]}
        keyboard={{
          enabled: true,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        spaceBetween={20}
        slidesPerView={1}
        loop={{ clickable: true }}
        // scrollbar={{ draggable: true }}
      >
        {data.map((value) => {
          const { id, name, img, zoner } = value;
          return (
            <SwiperSlide
              key={id}
              tw="relative h-screen border-2 border-blue-500"
            >
              <Image src={img} alt={name} />
              <TextOverlay>
                <OverlayTitle>{name}</OverlayTitle>
                <OverlayText> {zoner}</OverlayText>
              </TextOverlay>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </SwiperGroup>
  );
};

const Login = tw.section`h-screen overflow-hidden md:flex`;
const Logo = tw.img`h-8 w-auto mx-auto md:mx-0 mb-10 mt-8`;
const LogoHeading = tw.div`flex justify-start mt-5`;
const Heading = tw.h1`text-xl ml-6 text-black`;
const Container = tw.div`flex flex-col`;
const TextWrapper = tw.div`h-full w-full md:w-6/12 lg:w-4/12 relative md:overflow-x-hidden overflow-y-auto md:pl-8`;
const ImageWrapper = tw.div`hidden md:block md:w-6/12 lg:w-8/12 h-full`;
const Center = tw.div`grow flex flex-col`;
const Title = tw.h1`text-2xl text-black font-bold `;
const Desc = tw.h1`text-sm text-gray-600 font-normal mb-10`;
const SubmitButton = tw.button`w-full text-sm md:text-base  h-12 text-white grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed  !bg-primary-500  rounded-full shadow-md hover:shadow-lg mt-10`;
const BottomText = tw.p`text-sm mb-5 text-gray-800 flex justify-start`;
const ForgotPassword = tw.p`text-primary-500 text-sm ml-1  flex items-center justify-end`;
const SwiperGroup = tw.div` h-full overflow-hidden shadow-xl mx-auto md:w-full md:h-full opacity-100`;
const InputGroup = tw.div`w-full flex flex-col space-y-2 mt-5`;
// const FieldWrapper = tw.div`h-10 overflow-hidden border border-gray-700 relative rounded-full w-full flex items-center`;
const Image = tw.img`cursor-pointer object-cover w-full h-full`;
const TextOverlay = tw.div`hidden md:block md:absolute md:top-1/2 md:left-0 md:w-full md:h-full px-10 transform -translate-y-32 `;
const OverlayTitle = tw.h2` md:text-4xl md:w-10/12 lg:text-5xl lg:w-7/12 text-white md:leading-tight font-bold`;
const OverlayText = tw.p`text-base mt-2 text-white md:w-10/12 lg:w-6/12 mt-5 leading-relaxed`;
const FormContainer = tw.div`mt-16 mr-6`;
const SignUp = tw.p`text-primary-500 font-medium ml-1`;
const BackArrow = tw.div`cursor-pointer  flex items-center justify-center`;
const MailIcon = styled(MdEmail)`
  ${tw`text-gray-600 absolute ml-4 top-1/2 transform -translate-y-1/2`}
`;
const FieldWrapper = styled.div`
  ${tw`h-10 overflow-hidden border border-gray-700 relative rounded-full w-full flex items-center`}
`;
export default ForgetPage;
