import React, { useRef, useState } from "react";
import Images from "../Images";
import tw from "twin.macro";
import Config from "../Config";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/bundle";
import { useMutation } from "react-query";
import Loading from "../components/Loading";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HiArrowNarrowRight } from "react-icons/hi";

const OtpPage = () => {
  const data = [
    {
      id: 1,
      name: "Create an account",
      img: Images.PurpleBg,
      zoner: "Register your account if you want to create an App.",
    },
    {
      id: 2,
      name: "Build an app",
      img: Images.RedBg,
      zoner:
        "When you have chosen the API you want to use to build your app, you need to register your app. Follow the steps below",
    },
    {
      id: 3,
      name: "Add products",
      img: Images.BlueBg,
      zoner:
        "MTN is consistently developing new APIs for developers and businesses to create powerful products. Follow the steps to browse APIs",
    },
    {
      id: 4,
      name: "Access the keys",
      img: Images.BlackBg,
      zoner: "Access the keys",
    },
  ];
  const firstOTP = useRef(null);
  const secondOTP = useRef(null);
  const thirdOTP = useRef(null);
  const fourthOTP = useRef(null);
  const navigate = useNavigate();
  const captchaRef = useRef(null);
  const [forgotPassword, setForgotPassword] = useState(false);
  // const [loginLoading, setLoginLoading] = useState(false);
  const [formVal, setFormVal] = useState({
    emailMobile: "",
    password: "",
  });
  const [otpVal, setOtpVal] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
  });
  const loginFunction = async (body) => {
    return await axios.post(`${Config.baseUrl}/customer/otpVerify`, body, {
      headers: {
        "Content-Type": "application/json ",
      },
    });
  };

  const onloginSuccess = (data) => {
    // localStorage.setItem("user", JSON.stringify(data?.data));
    // window.location = Config.cmsUrl + "/cms";
    toast.success(data?.data?.msg || "Success");
  };

  const onloginError = (err) => {
    toast.error(err.response.data.msg || "An Error Occured", {
      position: toast.POSITION.TOP_LEFT,
    });
  };

  const { isLoading: loginLoading, mutate: loginMutate } = useMutation(
    loginFunction,
    {
      onSuccess: onloginSuccess,
      onError: onloginError,
    }
  );

  const initialValues = {
    loginId: "",
    password: "",
    // mode: mode || "1",
  };

  const OtpChangeHandler = (e) => {
    const { value, name } = e.target;
    if (value) {
      const length = value.length;
      if (value.length === 1) {
        setOtpVal((prev) => ({ ...prev, [name]: value }));
        if (name === "first") document.getElementsByName("second")[0]?.focus();
        else if (name === "second")
          document.getElementsByName("third")[0]?.focus();
        else if (name === "third")
          document.getElementsByName("fourth")[0]?.focus();
      }
    } else {
      setOtpVal((prev) => ({ ...prev, [name]: value }));
      if (name === "fourth") document.getElementsByName("third")[0]?.focus();
      else if (name === "third")
        document.getElementsByName("second")[0]?.focus();
      else if (name === "second")
        document.getElementsByName("first")[0]?.focus();
    }
  };
  const submitHandler = (values) => {
    const body = new FormData();
    body.append(
      "otp",
      otpVal?.first + otpVal?.second + otpVal?.third + otpVal?.fourth
    );
    body.append("email", values.email);

    body.append("mobilenumber", values.mobile);

    loginMutate(body);
  };

  function onChange(value) {
    // setCapcha(!isCapchaChecked);
  }

  return (
    <Login>
      <TextWrapperComp
        initialValues={initialValues}
        submitHandler={submitHandler}
        // alert={alert}
        captchaRef={captchaRef}
        onChange={onChange}
        loginLoading={loginLoading}
        OtpChangeHandler={OtpChangeHandler}
        otpVal={otpVal}
      />
      <ImageWrapper>
        <CarouselContainer data={data} />
      </ImageWrapper>
    </Login>
  );
};
const TextWrapperComp = ({
  initialValues,
  submitHandler,
  onChange,
  loginLoading,
  OtpChangeHandler,
  otpVal,
  captchaRef,
}) => (
  <TextWrapper>
    <Container className="Container">
      <LogoHeading>
        <Logo src={Images.Logo} alt="rapid test and trace logo" />

        {/* <Heading> Developer Portal </Heading> */}
      </LogoHeading>

      <Center>
        <Formik initialValues={initialValues} onSubmit={submitHandler}>
          <Form>
            <FormContainer>
              <Title>Enter OTP</Title>
              <Desc>To your account</Desc>
              <OtpInputWrapper>
                <OtpInput
                  type="number"
                  maxLength={1}
                  name="first"
                  value={otpVal.first}
                  required
                  onChange={OtpChangeHandler}
                  //   ref={firstOTP}
                />
                <OtpInput
                  type="number"
                  maxLength={1}
                  name="second"
                  value={otpVal.second}
                  required
                  onChange={OtpChangeHandler}
                  //   ref={secondOTP}
                />
                <OtpInput
                  type="number"
                  maxLength={1}
                  name="third"
                  value={otpVal.third}
                  required
                  onChange={OtpChangeHandler}
                  //   ref={thirdOTP}
                />
                <OtpInput
                  type="number"
                  maxLength={1}
                  name="fourth"
                  value={otpVal.fourth}
                  required
                  onChange={OtpChangeHandler}
                  //   ref={fourthOTP}
                />
              </OtpInputWrapper>
              <div>
                <SubmitButton type="submit" disabled={loginLoading}>
                  {loginLoading ? (
                    <Loading
                      color="white"
                      width={20}
                      height={20}
                      noPadding={true}
                    />
                  ) : (
                    "Confirm OTP"
                  )}
                </SubmitButton>
              </div>
            </FormContainer>
          </Form>
        </Formik>
      </Center>

      <BottomText>
        <p className="mr-1">Already have an account?</p>
        <Link
          to="/login"
          className="text-yellow-300 flex items-center justify-start"
          // tw="text-xs text-gray-400 flex justify-start"
        >
          <p className="">click here</p>
        </Link>
      </BottomText>
    </Container>
  </TextWrapper>
);

const CarouselContainer = ({ data }) => {
  return (
    <SwiperGroup className="home-carousel">
      <Swiper
        className="h-screen"
        modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y]}
        keyboard={{
          enabled: true,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        spaceBetween={20}
        slidesPerView={1}
        loop={{ clickable: true }}
        // scrollbar={{ draggable: true }}
      >
        {data.map((value) => {
          const { id, name, img, zoner } = value;
          return (
            <SwiperSlide
              key={id}
              tw="relative h-screen border-2 border-blue-500"
            >
              <Image src={img} alt={name} />
              <TextOverlay>
                <OverlayTitle>{name}</OverlayTitle>
                <OverlayText> {zoner}</OverlayText>
              </TextOverlay>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </SwiperGroup>
  );
};

const Login = tw.section`h-screen overflow-hidden md:flex`;
const Logo = tw.img`h-10 w-auto mx-auto md:mx-0 mb-10 mt-8`;
const LogoHeading = tw.div`flex justify-start mt-5`;
const Heading = tw.h1`text-xl ml-6 text-black`;
const Container = tw.div`flex flex-col`;
const TextWrapper = tw.div`h-full w-full md:w-6/12 lg:w-4/12 relative md:overflow-x-hidden overflow-y-auto md:pl-8`;
const ImageWrapper = tw.div`hidden md:block md:w-6/12 lg:w-8/12 h-full`;
const Center = tw.div`grow flex flex-col`;
const Title = tw.h1`text-3xl text-black font-bold `;
const Desc = tw.h1`text-sm text-gray-600 font-normal mb-10`;
const SubmitButton = tw.button`w-full text-sm md:text-base  h-12 text-white grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed  !bg-primary-500  rounded-full shadow-md hover:shadow-lg mt-10`;
const BottomText = tw.p`text-xs text-gray-400 flex justify-start`;
const ForgotPassword = tw.p`text-primary-500 text-sm ml-1  flex items-center justify-end`;
const SwiperGroup = tw.div` h-full overflow-hidden shadow-xl mx-auto md:w-full md:h-full opacity-100`;
const InputGroup = tw.div`w-full flex flex-col space-y-2 mt-5`;
const FieldWrapper = tw.div`h-10 overflow-hidden border border-gray-400 relative rounded-full w-full flex items-center`;
const Image = tw.img`cursor-pointer object-cover w-full h-full`;
const TextOverlay = tw.div`hidden md:block md:absolute md:top-1/2 md:left-0 md:w-full md:h-full px-10 transform -translate-y-32 `;
const OverlayTitle = tw.h2` md:text-4xl lg:text-6xl text-white font-bold`;
const OverlayText = tw.p`text-base mt-2 text-white md:w-8/12`;
const FormContainer = tw.div`mt-16 mr-6`;
const OtpInput = tw.input`w-full w-12  text-center py-4 rounded-lg font-medium bg-gray-100 border border-gray-300 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white `;
const OtpInputWrapper = tw.div`w-full flex justify-between items-center mt-6`;
export default OtpPage;
