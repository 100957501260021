import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header from "../headers/light.js";

import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import DesignIllustration from "../../images/design-illustration-2.svg";
import CustomersLogoStripImage from "../../images/customers-logo-strip.png";
import Images from "../../Images.js";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-16 md:py-12`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-start`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base  text-gray-700`;

const Actions = styled.div`
  ${tw`relative max-w-md flex justify-center items-center lg:justify-start text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw` bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

export default ({ roundedHeaderButton, user, primaryLinkUrl = "/sign-up" }) => {
  return (
    <>
      <Header roundedHeaderButton={roundedHeaderButton} user={user} />
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>
              Unleash the Power of{" "}
              <span tw="text-primary-500">SMS Communication</span>{" "}
              {/* Engage Your <span tw="text-primary-500">Audience</span> At Scale{" "} */}
            </Heading>
            {/* <Paragraph>Using Bulk SMS!</Paragraph> */}

            <Paragraph>
              {/* Unleash the Power of SMS Communication{" "} */}
              {/* <span tw="text-gray-900 font-medium"> */}
              With our powerful platform,
              {/* </span>{" "} */}
              you can unlock the full potential of messaging, enabling instant
              connections and catalyzing meaningful conversations.
            </Paragraph>
            <Actions>
              <a href={primaryLinkUrl}>
                <button>Register</button>
              </a>
            </Actions>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img
                tw="min-w-0 w-full max-w-xl "
                src={Images.LandingImage1}
                alt="Design Illustration"
              />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
    </>
  );
};
